.banner-header {
    margin-top: -1.5rem;
    /* margin-bottom: 1.5rem; */
    height: 30rem;
    position: relative;
    overflow: hidden;
    background-color: #467fcf;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-image {
    width: 100%;
    object-fit: cover;
}

/* .hover-food:hover .text-muted {
    color: #da4b0d !important;
}

.hover-toy:hover .text-muted {
    color: #002c9d !important;
}

.hover-diamond:hover .text-muted {
    color: #e6c619 !important;
}

.hover-dress:hover .text-muted {
    color: #790f59 !important;
}

.hover-healthly:hover .text-muted {
    color: #2b3e43 !important;
}

.hover-art:hover .text-muted {
    color: #3866a6 !important;
} */
/* ###### utilities ##### */

#categorySlider:first-child {
    padding-left: 0;
}
#categorySlider {
    margin-top: 10px;
    padding: 0 10px;
}

.cursor-pointer {
    cursor: pointer;
}

#categories {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 35px;
    margin-left: 20px;
    cursor: pointer;
    user-select: none;
    background: #343a40;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 0px 5px 0 #467fcf;
}

#categories:hover {
    box-shadow: none;
}

@media (max-width: 768px) {
    .slick-slider {
        margin: inherit !important;
    }
}

#searchBar {
    height: 3.75rem;
    z-index: 10001;
}

#vm_banner_images {
    z-index: 2 !important;
    position: absolute !important;
}

#vm_social_left {
    height: initial !important;
}
